<template>
  <div class="sticky">
    <div class="top-box">
      <van-dropdown-menu>
        <van-dropdown-item v-model="hotelId" :options="hotelList" @change="changeHotel"/>
      </van-dropdown-menu>
      <div class="flex-center-center" style="margin-top:10px">
        <img src="@/assets/images/roomStateControl/left.png" class="icon" @click="lastWeek()">
        <p class="time">{{beginDate}}-{{endDate}}</p>
        <img src="@/assets/images/roomStateControl/right.png" class="icon" @click="nexteWeek()">
      </div>
    </div>
    <ul class="flex-start-center">
      <li v-for="item in weekList" :key="item.value" class="item">
        <p :class="[{weekend:item.weekday == '六' || item.weekday == '日'},'week']">周{{item.weekday}}</p>
        <p :class="[{weekend:item.weekday == '六' || item.weekday == '日'},'date']">{{item.date}}</p>
      </li>
    </ul>
  </div>
</template>
<script>
import { getNextDate, getLastDate,formatDate } from "@/tool/common";
import { list } from "@/api/hotel.js";
export default {
  data() {
    return {
      hotelId: "",
      beginDate: "",
      endDate: "",
      weekList: [],
      hotelList: [],
    };
  },
  mounted() {
    this.init();
    this.getHotelList();
  },
  methods: {
    // 酒店列表
    async getHotelList() {
      const res = await list();
      this.hotelList = res.map((item) => {
        return { text: item.name, value: item.id };
      });
      this.hotelId = this.hotelList[0].value;
      this._getPriceStrategy()
    },
    // 切换酒店
    changeHotel(e){
      this.hotelId = e
      this._getPriceStrategy()
    },
    // 初始化周期
    init() {
      this.weekList = getNextDate(7).slice(0, 7);
      this.beginDate = this.weekList[0].value;
      this.endDate = this.weekList[6].value;
    },
    // 下一周
    nexteWeek() {
      this.weekList = getNextDate(7, this.endDate).slice(1);
      this.beginDate = this.weekList[0].value;
      this.endDate = this.weekList[6].value;
      this._getPriceStrategy()
    },
    // 上一周
    lastWeek() {
      if(this.weekList[0].value == formatDate().value ){
        this.$toast('无法查询今天之前的数据')
        return
      }
      this.weekList = getLastDate(-7, this.beginDate).slice(0, 7);
      this.beginDate = this.weekList[0].value;
      this.endDate = this.weekList[6].value;
      this._getPriceStrategy()
    },
    // 通知父组件显示弹窗信息
    _getPriceStrategy() {
      this.$emit("getPriceStrategy", {
        beginDate: this.beginDate,
        endDate: this.endDate,
        hotelId: this.hotelId,
        salesChannel: 1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-box {
  padding-bottom: 34px;
  background: #00a8a8;
  ::v-deep .van-dropdown-menu__bar {
    background-color: #00a8a8;
    box-shadow: none;
  }
  ::v-deep .van-dropdown-menu__title {
    font-size: 28px;
    color: #ffffff;
  }
  ::v-deep .van-dropdown-item__option--active {
    color: #00a8a8;
  }
  ::v-deep .van-dropdown-item__icon {
    color: #00a8a8;
  }
  .icon {
    width: 42px;
    height: 42px;
  }
  .time {
    font-size: 32px;
    color: #ffffff;
    margin: 0 14px;
  }
}
.item {
  flex-grow: 1;
  text-align: center;
  padding: 14px 0;
  background: #ffffff;
  border-right: 1px solid #e8e8e8;
  .week {
    color: #78828d;
  }
  .date {
    color: #30304b;
    font-weight: bold;
    font-size: 32px;
    margin-top: 2px;
  }
  .weekend {
    color: #ff5733;
  }
}
</style>