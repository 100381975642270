<template>
	<div class="text-center">
		<img class="image" src="@/assets/images/common/empty.png" />
        <div v-if="title" class="title">{{title}}</div>
        <div v-if="supTitle" class="sup-title">{{supTitle}}</div>
	</div>
</template>

<script>
	export default {
		name: "empty",
		props:['title','supTitle']
	};
</script>

<style lang="scss" scoped>
.image{
    width: 286px;
    height: 183px;
    margin-top: 50px;
}
.title{
    font-size: 34px;
    font-weight: 500;
    margin-top: 25px;
}
.sup-title{
    font-size: 30px;
    color: #999999;
    margin-top: 25px;
}
</style>
