<template>
  <div>
    <top ref="top" @getPriceStrategy="getPriceStrategy" />
    <template v-if="roomList.length > 0">
      <room v-for="item in roomList" :room="item" :key="item.roomId" class="m-t-46" @openSetting="openSetting" />
    </template>
    <empty v-else title="您还没添加任何价格策略" supTitle="请先在PC端后台添加价格策略" />
    <setting ref="setting" @refresh="refresh"/>
  </div>
</template>

<script>
import top from "./components/top.vue";
import room from "./components/room.vue";
import setting from "./components/setting.vue";
import empty from "@/components/common/empty.vue";
import { priceStrategy } from "@/api/hotel.js";
import {getNextDate} from "@/tool/common/index"
export default {
  components: { top, room, setting, empty },
  data() {
    return {
      roomList: [],
    };
  },
  methods: {
    // 打开设置
    openSetting(e) {
      this.$refs.setting.open(e);
    },
    // 获取酒店的房型以及价格策略
    async getPriceStrategy(e) {
      e.endDate = getNextDate(1,e.endDate)[1].value
      const res = await priceStrategy(e);
      this.roomList = res;
    },
    // 刷新酒店的房型以及价格策略
    async refresh(){
      const res = await priceStrategy({
        beginDate: this.$refs.top.beginDate,
        endDate: getNextDate(1,this.$refs.top.endDate)[1].value,
        hotelId: this.$refs.top.hotelId,
        salesChannel: 1,
      });
      this.roomList = res;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>