<template> 
  <div>
    <div class="title ellipsis">{{room.roomName}}</div>
    <div v-for="item in room.priceStrategies" :key="item.id">
      <div class="room-name">{{item.name}}</div>
      <ul class="flex-start-center m-b-20">
        <li v-for="(child,index) in item.prices" :key="index" :class="[{'open':child.enabled},'item']" @click="openSetting(child,item)">
          <p class="font-30">{{child.enabled ? '开':'关'}}</p>
          <p class="date">{{child.totalQuantity}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    room:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  methods: {
    openSetting(e,strategy) {
      this.$emit("openSetting",{...e,...{strategeName:strategy.name,strategyId:strategy.id,roomName:this.room.roomName}});
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-top: 12px;
  padding-bottom: 12px;
  width: 206px;
  text-align: center;
  background: #5e87ff;
  border-radius: 0 181px 181px 0;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
}
.room-name {
  font-size: 28px;
  font-weight: bold;
  color: #30304b;
  padding: 30px;
  border-bottom: 1px solid #e8e8e8;
  background-color: #ffffff;
}
.item {
  flex-grow: 1;
  text-align: center;
  padding: 14px 0;
  background: #ffffff;
  border-right: 2px solid #e8e8e8;
  color: #78828d;
  font-weight: 500;
  .date {
    font-size: 24px;
    margin-top: 2px;
  }
  .weekend {
    color: #ff5733;
  }
}
.open {
  background-color: rgba(0, 168, 168, 0.08);
  color: #00a8a8;
}
</style>