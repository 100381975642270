<template>
  <div>
    <van-popup v-model="show" round closeable position="bottom">
      <div class="box">
        <p class="room">{{roomName}}</p>
        <p class="bed">{{strategeName}}</p>
        <div class="flex-start-center content">
          <div class="left">时间范围</div>
          <div class="bold time" @click="openCalendar">{{formatStart}}-{{formatEnd}}</div>
          <van-icon class="arrow" name="arrow" />
        </div>
        <div class="flex-start-center content">
          <div class="left">房态</div>
          <van-switch v-model="enabled" size="24px" active-color="#00A8A8" />
        </div>
        <div class="flex-start-center content">
          <div class="left">库存</div>
          <input class="bold" type="number" v-model="totalQuantity" placeholder="请输入库存" />
        </div>
        <div class="flex-between-center">
          <div class="cancel" @click="close">取消</div>
          <div class="sure" @click="sure">确认</div>
        </div>
      </div>
    </van-popup>
    <van-calendar v-model="calendarShow" allow-same-day :default-date="defaultDate" type="range" @confirm="confirm" />
  </div>
</template>

<script>
import { formatDate } from "@/tool/common";
import {updatePriceStrategy} from "@/api/hotel.js"
export default {
  data() {
    return {
      show: false,
      calendarShow: false,
      strategyId:'',
      roomName: "",
      strategeName: "",
      beginDate: "",
      endDate: "",
      enabled:false,
      totalQuantity:0
    };
  },
  computed: {
    formatStart() {
      return formatDate(this.beginDate).value;
    },
    formatEnd() {
      return formatDate(this.endDate).value;
    },
    defaultDate() {
      return [new Date(this.beginDate), new Date(this.endDate)];
    },
  },
  methods: {
    // 打开设置弹窗
    open(e) {
      this.roomName = e.roomName;
      this.strategeName = e.strategeName;
      this.beginDate = e.date;
      this.endDate = e.date;
      this.enabled = e.enabled;
      this.totalQuantity = e.totalQuantity;
      this.strategyId = e.strategyId
      this.show = true;
    },
    // 关闭设置弹窗
    close() {
      this.show = false;
    },
    // 设置确认
    sure(){
      if(this.totalQuantity == ''){
        this.$toast('库存不能为空')
        return
      }
      updatePriceStrategy({
        priceStrategyId:this.strategyId,
        applicableWeek:[0,1,2,3,4,5,6],
        costPriceUpdateType:0,
        salePriceUpdateType:0,
        beginDate:this.formatStart,
        endDate:this.formatEnd,
        enabled:this.enabled,
        totalQuantity:this.totalQuantity,
      }).then(res=>{
        this.close()
        this.$toast.success('设置成功');
        this.$emit('refresh')
      })
    },
    // 日历确认
    confirm(e){
      this.beginDate = e[0];
      this.endDate = e[1];
      this.closeCalendar()
    },
    // 打开日历
    openCalendar() {
      this.calendarShow = true;
    },
    // 关闭日历
    closeCalendar() {
      this.calendarShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  color: #30304b;
  text-align: center;
  padding-bottom: 30px;
  .room {
    font-size: 34px;
    font-weight: bold;
    padding-top: 24px;
    margin-bottom: 8px;
  }
  .bed {
    padding-bottom: 24px;
    font-size: 24px;
    border-bottom: 1px solid #e0e0e0;
  }
  .content {
    border-bottom: 1px solid #e0e0e0;
    position: relative;
    .left {
      width: 128px;
      font-size: 32px;
      color: #78828d;
      margin-right: 52px;
      padding: 30px 0;
      margin-left: 34px;
      text-align: left;
    }
    .time {
      margin-right: 168px;
    }
    .arrow {
      position: absolute;
      top: 50%;
      right: 36px;
      margin-top: -8px;
    }
  }
  .cancel {
    width: 338px;
    padding: 20px 0;
    text-align: center;
    border-radius: 46px;
    border: 2px solid #e0e0e0;
    font-size: 32px;
    font-weight: bold;
    margin-top: 65px;
    margin-left: 36px;
  }
  .sure {
    width: 338px;
    padding: 20px 0;
    text-align: center;
    border-radius: 46px;
    border: 2px solid #e0e0e0;
    font-size: 32px;
    font-weight: bold;
    margin-top: 65px;
    background-color: #00a8a8;
    color: #ffffff;
    margin-right: 32px;
  }
}
</style>